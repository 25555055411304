import mixpanel from "mixpanel-browser";
import { DialogWindow } from "~/utils/api.interfaces.enums";

declare let window: DialogWindow;

// Initialize the Mixpanel client

if (typeof window !== "undefined") {
  const MIXPANEL_TOKEN = window.ENV.MIXPANEL_PROJECT_TOKEN || "__TOKEN__";
  mixpanel.init(MIXPANEL_TOKEN, {
    debug: process.env.NODE_ENV === "development",
    track_pageview: true,
    persistence: "localStorage",
  });
}

// Utility for tracking events
export const MixPanel = {
  identify: (id: string) => {
    if (typeof window !== "undefined") mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (typeof window !== "undefined") mixpanel.alias(id);
  },
  track: (name: string, props?: Object) => {
    if (typeof window !== "undefined") mixpanel.track(name, props);
  },
  trackLinks: (query: string, name: string) => {
    if (typeof window !== "undefined") mixpanel.track_links(query, name);
  },
  trackForms: (query: string, name: string) => {
    if (typeof window !== "undefined") mixpanel.track_forms(query, name);
  },
  people: {
    set: (props: Object) => {
      if (typeof window !== "undefined") mixpanel.people.set(props);
    },
    setOnce: (props: Object) => {
      if (typeof window !== "undefined") mixpanel.people.set_once(props);
    },
    increment: (prop: string) => {
      if (typeof window !== "undefined") mixpanel.people.increment(prop);
    },
    append: (prop: string, value: any) => {
      if (typeof window !== "undefined") mixpanel.people.append(prop, value);
    },
  },

  /**
   * Initializes analytics tracking for a user across platforms (MixPanel and Intercom)
   *
   * @param {Object} options - Configuration options for user tracking
   * @param {Object} options.user - User data object
   * @param {string} options.user.email - User email
   * @param {string} [options.user.id] - User ID
   * @param {Object} [options.user.profile] - User profile information
   * @param {string} [options.user.profile.firstName] - User first name
   * @param {string} [options.user.profile.lastName] - User last name
   * @param {string} [options.user.profile.imageUrl] - User profile image URL
   * @param {Object} [options.team] - Team data object for Intercom company tracking
   * @param {string} [options.team.id] - Team/Organization ID
   * @param {string} [options.team.name] - Team/Organization name
   * @param {Function} [options.intercomUpdate] - Intercom update function
   * @param {number} [options.delay=500] - Delay for Intercom update in milliseconds
   * @returns {void}
   */
  initUserTracking: (options: {
    user: {
      email: string;
      id?: string;
      profile?: {
        firstName?: string;
        lastName?: string;
        imageUrl?: string;
      };
    };
    team?: {
      id?: string;
      name?: string;
    };
    intercomUpdate?: Function;
    delay?: number;
  }) => {
    const { user, team, intercomUpdate, delay = 500 } = options;

    if (!user?.email) return;

    // MixPanel Tracking
    MixPanel.identify(user.email);

    MixPanel.people.set({
      $email: user.email,
      ...(user.id && { user_id: user.id }),
      ...(user.profile?.firstName && { $first_name: user.profile.firstName }),
      ...(user.profile?.lastName && { $last_name: user.profile.lastName }),
      ...(user.profile?.imageUrl && { $avatar: user.profile.imageUrl }),
    });

    // Intercom Tracking (if provided)
    if (intercomUpdate && typeof intercomUpdate === "function") {
      setTimeout(() => {
        intercomUpdate({
          name: `${user.profile?.firstName || ""} ${
            user.profile?.lastName || ""
          }`.trim(),
          email: user.email,
          avatar: user.profile?.imageUrl
            ? {
                type: "avatar",
                imageUrl: user.profile.imageUrl,
              }
            : undefined,
          ...(team && {
            company: {
              companyId: team.id || "",
              name: team.name || "",
            },
          }),
        });
      }, delay);
    }
  },
};

export default MixPanel;
